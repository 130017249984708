import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Stack, Typography, Link as MuiLink } from "@mui/material";
import IMG_9737 from "../assets/IMG_9737.png";
import IMG_6386 from "../assets/IMG_6386.png";
import IMG_2793 from "../assets/IMG_2793.jpeg";
import grassSvg from "../assets/grass.svg";
import cowSvg from "../assets/cow.svg";
import mobGrazingSvg from "../assets/mob-grazing.svg";
import localSvg from "../assets/local.svg";
import { FirstSection, Section, SectionItem } from "../styles/Layout";
import { colors } from "../theme";

const FactsImage = styled.img`
  max-width: 60%;
  max-height: 200px;
  flex-grow: 1;
  object-fit: contain;

  @media screen and (max-width: 895px) {
    max-width: 100%;
    max-height: 100px;
  }
`;

const FindOutMoreContent = styled.div`
  border: 2px solid transparent;
  margin: 8px;
  text-align: center;

  &:hover {
    border-color: ${colors.dark};
  }
`;

const Home = () => {
  return (
    <div>
      <FirstSection position="relative">
        <img className="bottom" src={IMG_9737} alt="killantringan farm" />
        <Stack
          position="absolute"
          top="0"
          left="0"
          width="100%"
          paddingY={{ xs: 4, md: 8 }}
          paddingX={{ xs: 2, md: 8 }}
          zIndex={2}
          justifyContent="center"
          gap={2}
        >
          <Typography variant="h1" align="center" color="white" letterSpacing={2}>
            Regenerative Farming
          </Typography>
          <Stack direction="row" gap={3} justifyContent="center">
            {/* <Link to="/shop" style={{ width: "auto" }}>
              <Button variant="contained" size="large" color="secondary">
                Farm shop
              </Button>
            </Link> */}
            <Link to="/accommodation" style={{ width: "auto" }}>
              <Button variant="contained" size="large" color="secondary">
                Book lodge
              </Button>
            </Link>
          </Stack>
        </Stack>
      </FirstSection>
      <Section direction="row">
        <SectionItem padding={{ xs: 4, md: 8 }} border borderBottom={{ xs: `2px solid ${colors.dark}`, md: 0 }}>
          <Typography variant="h3">
            Killantringan forms part of the farming enterprise on{" "}
            <MuiLink href="https://www.dunskey.com/" target="_blank" rel="noreferrer">
              Dunskey Estate
            </MuiLink>
            . It is situated on the Southwest coast of Scotland, overlooking the Irish sea. We are embarking on a path to
            regenerate the soil and produce high-quality meat from livestock on natural diets. We also prioritize wildlife,
            striving to provide a suitable environment for all animals, flora, and fauna to flourish.
          </Typography>
        </SectionItem>
        <SectionItem>
          <img src={IMG_6386} alt="cows" />
        </SectionItem>
      </Section>
      <Section alignItems="center">
        <SectionItem padding={{ xs: 4, md: 8 }} maxWidth={{ xs: "100%", md: "60%" }}>
          <Typography variant="h3" align="center">
            Our mission is to produce natural food whilst improving the environment.
          </Typography>
        </SectionItem>
      </Section>
      <Section direction="row" maxHeight="50vh">
        <SectionItem>
          <img src={IMG_2793} alt="cow" />
        </SectionItem>
      </Section>

      <Section direction="row">
        <SectionItem direction={{ xs: "column", md: "row" }} padding={{ xs: 4, md: 8 }} gap={3}>
          <Stack flex={1}>
            <Typography variant="h3" align="center" marginBottom={2}>
              Our Story
            </Typography>
            <Typography>
              Killantringan is a livestock farm situated on the coast overlooking the Irish Sea in Galloway, Southwest Scotland.
              Paddy and Janie Dear took over the farm in November 2021 and, under the management of Harry Morgan, they have
              embarked on a project to transition the farm from traditional methods to regenerative practices. This change
              involves a shift in thinking, practices, and grazing methods. Over the coming years, we hope to see a positive
              impact as we aim to improve the soil and its productivity, diversity, animal health, and wildlife. We would like to
              see the return of curlew and lapwing that were in such abundance during Janie's childhood.
            </Typography>
          </Stack>
          <Stack flex={1}>
            <Typography>
              The essential elements for farming livestock at Killantringan are to ensure they get their natural diet. Thus, the
              cattle and sheep are entirely grass-fed and eat fresh pasture every day ("mob grazed"). We have a suckler herd of
              native breed, Aberdeen Angus cattle. Our flock of Improved Welsh breeding ewes is growing year by year, and like the
              cattle, the sheep are moved frequently to allow the pasture to flourish, improve, and diversify.
            </Typography>
            <Typography>
              We follow organic practices and aim for a very low input system, avoiding the use of chemical fertilizers,
              herbicides, or pesticides. We aspire to have diverse pasture with a variety of grasses, legumes, herbs, and
              wildflowers. This provides a healthier diet for the animals, sequesters carbon from the atmosphere, and supports a
              vibrant ecosystem of wildlife. All of this contributes to improving soil health every year. We want healthy
              livestock and plants, not only above ground but below ground as well. We aim to encourage a thriving community of
              worms, nematodes, bacteria, and mycorrhizal fungi to function and improve soil health.
            </Typography>
          </Stack>
        </SectionItem>
      </Section>

      <Section direction="row">
        <SectionItem border alignItems="center" justifyContent="space-between" padding={3} gap={2}>
          <FactsImage src={grassSvg} alt="grass" />
          <Typography variant="h3" align="center">
            100% Grass Fed Beef
          </Typography>
        </SectionItem>
        <SectionItem border alignItems="center" justifyContent="space-between" padding={3} gap={2}>
          <FactsImage src={cowSvg} alt="cow" />
          <Typography variant="h3" align="center">
            Traditional Native Breeds
          </Typography>
        </SectionItem>
        <SectionItem border alignItems="center" justifyContent="space-between" padding={3} gap={2}>
          <FactsImage src={mobGrazingSvg} alt="mob grazing" />
          <Typography variant="h3" align="center">
            Mob Grazed Daily
          </Typography>
        </SectionItem>
        <SectionItem alignItems="center" justifyContent="space-between" padding={3} gap={2}>
          <FactsImage src={localSvg} alt="local" />
          <Typography variant="h3" paddingTop={2} align="center">
            Regenerative Farming
          </Typography>
        </SectionItem>
      </Section>

      <Section className="find-out-more">
        <Link to="/about">
          <FindOutMoreContent>
            <Typography variant="h3" margin="45px auto">
              Find out more
            </Typography>
          </FindOutMoreContent>
        </Link>
      </Section>
    </div>
  );
};

export default Home;
