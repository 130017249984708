import { createTheme } from "@mui/material";

export const colors = {
  dark: "#121710",
  light: "#fcf8f0",
} as const;

export const fonts = {
  regular: "GillSans-Light",
  regularBold: "GillSans",
  special: "BaskOldFace",
} as const;

export const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          fontFamily: fonts.regularBold,
          boxShadow: "none",
        },
      },
    },
  },
  typography: {
    fontFamily: `${fonts.regular}, "Gill Sans MT", Calibri, "Trebuchet MS"`,
    h1: {
      fontFamily: fonts.special,
      fontSize: "7vw",
      margin: 0,
      lineHeight: "8vw",
      "@media (max-width:895px)": {
        fontSize: "calc(5vw + 5px)",
        lineHeight: "48px",
      },
    },
    h2: {
      fontFamily: fonts.special,
      fontSize: "5vw",
      margin: 0,
      lineHeight: "6vw",
      "@media (max-width:895px)": {
        fontSize: 32,
        lineHeight: "38px",
      },
    },
    h3: {
      fontFamily: fonts.special,
      fontSize: "3vw",
      margin: 0,
      lineHeight: "4vw",
      "@media (max-width:895px)": {
        fontSize: 28,
        lineHeight: "34px",
      },
    },
    subtitle1: {
      fontFamily: fonts.regularBold,
    },
    subtitle2: {
      fontFamily: fonts.regularBold,
    },
    body1: {
      fontSize: 18,
    },
    body2: {
      fontSize: 16,
    },
  },
  palette: {
    primary: {
      main: colors.dark,
    },
    secondary: {
      main: colors.light,
    },
  },
});
