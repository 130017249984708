import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Section } from "../../styles/Layout";
import { colors } from "../../theme";
import logo from "../../assets/logo.svg";
import { NavItem, NavItemContainer } from "./styles";
import { NavLink } from "react-router-dom";

type Props = {
  onClose: () => void;
  open: boolean;
};

const MobileNavDrawer = ({ onClose, open }: Props) => {
  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Box
        bgcolor={colors.light}
        width={{ xs: "85vw", md: "45vw" }}
        height="auto"
        minHeight="100%"
        border={`2px solid ${colors.dark}`}
        overflow="auto"
      >
        <Section paddingX={2} paddingY={1.5} direction="row" justifyContent="space-between" alignItems="center" flexWrap="nowrap">
          <IconButton onClick={onClose} style={{ width: "50px", height: "50px" }}>
            <Close color="primary" fontSize="large" />
          </IconButton>
          <NavLink to="/" style={{ padding: "0 32px" }} onClick={onClose}>
            <img src={logo} alt="killantringan farm" />
          </NavLink>
          <div style={{ minWidth: "50px" }}></div>
        </Section>

        <Stack onClick={onClose} paddingY={4} paddingX={2}>
          <NavItem to="/">
            <NavItemContainer>
              <Typography>HOME</Typography>
            </NavItemContainer>
          </NavItem>
          <NavItem to="/shop">
            <NavItemContainer>
              <Typography>SHOP</Typography>
            </NavItemContainer>
          </NavItem>
          <NavItem to="/about">
            <NavItemContainer>
              <Typography>ABOUT</Typography>
            </NavItemContainer>
          </NavItem>
          <NavItem to="/accommodation">
            <NavItemContainer>
              <Typography>ACCOMMODATION</Typography>
            </NavItemContainer>
          </NavItem>
          <NavItem to="/contact">
            <NavItemContainer>
              <Typography>CONTACT</Typography>
            </NavItemContainer>
          </NavItem>

          <a href="https://www.instagram.com/killantringanfarms/" target="_blank" rel="noreferrer">
            <NavItemContainer>
              <Typography>@KILLANTRINGANFARMS</Typography>
            </NavItemContainer>
          </a>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default MobileNavDrawer;
