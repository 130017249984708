import { Stack } from "@mui/material";
import styled from "styled-components";
import { colors } from "../theme";

export const Section = styled(Stack)`
  width: 100%;
  border-bottom: 2px solid ${colors.dark};

  @media (max-width: 895px) {
    flex-wrap: wrap;
  }
`;

export const FirstSection = styled(Section)`
  height: calc(100vh - 150px);

  @media (max-width: 895px) {
    height: auto;
  }
`;

export const SectionItem = styled(Stack)<{ border?: boolean }>`
  align-items: center;
  flex-basis: 100%;
  height: auto;

  ${({ border }) => border && `border-right: 2px solid ${colors.dark};`}

  @media (max-width: 895px) {
    flex-basis: 100%;
    border-right: 0;
  }
`;
