import { Link } from "react-router-dom";
import { Box, Button, Drawer, IconButton, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { colors, fonts } from "../../theme";
import { useGetBasketQuery, useRemoveItemFromBasketMutation, useUpdateItemInBasketMutation } from "../../generated/graphql";
import { Section, SectionItem } from "../../styles/Layout";
import { toPrice } from "../../helpers/money";

type Props = {
  onClose: () => void;
  open: boolean;
};

const BasketDrawer = ({ onClose, open }: Props) => {
  const basketId = localStorage.getItem("basketId") || "";
  const { data } = useGetBasketQuery({ variables: { basketId } });
  const [updateItem, { data: updateItemData, loading: updatingBasket }] = useUpdateItemInBasketMutation();
  const [removeItem, { data: removeItemData, loading: removingItemFromBasket }] = useRemoveItemFromBasketMutation();
  const basket = data?.cart;
  const lineItems = basket?.lines?.nodes || [];
  const dropdownOptions = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  const errorMessage =
    updateItemData?.cartLinesUpdate?.userErrors[0]?.message || removeItemData?.cartLinesRemove?.userErrors[0]?.message;

  const handleQuantityChange = (event: SelectChangeEvent, lineId: string) => {
    updateItem({
      variables: {
        basketId,
        lineId,
        quantity: parseInt(event.target.value),
      },
    });
  };

  const handleRemoveItem = (lineId: string) => {
    removeItem({
      variables: {
        basketId,
        lineId,
      },
    });
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        bgcolor={colors.light}
        width={{ xs: "85vw", md: "45vw" }}
        height="auto"
        minHeight="100%"
        border={`2px solid ${colors.dark}`}
        overflow="auto"
      >
        <Section padding={2} direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Basket</Typography>
          <IconButton onClick={onClose} style={{ width: "50px", height: "50px" }}>
            <Close color="primary" fontSize="large" />
          </IconButton>
        </Section>

        {basket && lineItems.length ? (
          <>
            <Stack>
              {lineItems.map((lineItem) => (
                <Section key={lineItem.id} direction="row" flexWrap="nowrap">
                  <SectionItem flexBasis="40%" border height="15vh" padding={1}>
                    <Box border={`2px solid ${colors.dark}`} width="100%" height="100%">
                      <img src={lineItem.merchandise.product.images.nodes[0].src} alt={"basket item"} />
                    </Box>
                  </SectionItem>
                  <SectionItem padding={2} flexBasis="60%" alignItems="start" justifyContent="center" gap={2} position="relative">
                    <Stack direction="row" alignItems="center" gap={2} width="100%">
                      <Select
                        id="quantity-select"
                        size="small"
                        value={lineItem.quantity.toString()}
                        onChange={(event: SelectChangeEvent) => handleQuantityChange(event, lineItem.id)}
                      >
                        {dropdownOptions.slice(0, lineItem.merchandise.quantityAvailable || 10).map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </Select>
                      <Stack flex={1}>
                        <Typography>{lineItem.merchandise.product.title}</Typography>
                        <Typography fontFamily={fonts.regularBold}>{toPrice(lineItem.cost.amountPerQuantity.amount)}</Typography>
                      </Stack>
                      <IconButton onClick={() => handleRemoveItem(lineItem.id)}>
                        <Close color="primary" />
                      </IconButton>
                    </Stack>
                  </SectionItem>
                </Section>
              ))}
            </Stack>
            <Section padding={2}>
              <Typography variant="h5" fontFamily={fonts.regularBold}>
                Total: {toPrice(basket.cost.subtotalAmount.amount)}
              </Typography>
            </Section>
            <Section padding={2}>
              <a href={basket?.checkoutUrl}>
                <LoadingButton variant="contained" size="large" fullWidth loading={updatingBasket || removingItemFromBasket}>
                  Checkout
                </LoadingButton>
              </a>
            </Section>
            {errorMessage && (
              <Box padding={2}>
                <Typography color="error">{errorMessage}</Typography>
              </Box>
            )}
          </>
        ) : (
          <Stack alignItems="center">
            <Typography padding={4}>Your basket is empty</Typography>
            <Box>
              <Link to="/shop" onClick={onClose}>
                <Button variant="contained">Go to shop</Button>
              </Link>
            </Box>
          </Stack>
        )}
      </Box>
    </Drawer>
  );
};

export default BasketDrawer;
