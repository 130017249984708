import React from "react";
import { Button, List, ListItem, Stack, Typography } from "@mui/material";
import bothyLiving from "../assets/bothy_living.jpeg";
import bothyKitchen from "../assets/bothy_kitchen.jpeg";
import { Section, SectionItem } from "../styles/Layout";
import { colors } from "../theme";

const Accommodation: React.FC = () => {
  return (
    <div>
      <Section height="70vh" direction="row">
        <SectionItem height={{ xs: "50%", md: "100%" }} border borderBottom={{ xs: `2px solid ${colors.dark}`, md: 0 }}>
          <img src={bothyLiving} alt="bothy living area" className="top" />
        </SectionItem>
        <SectionItem height={{ xs: "50%", md: "100%" }}>
          <img src={bothyKitchen} alt="bothy kitchen area" />
        </SectionItem>
      </Section>
      <Section>
        <SectionItem padding={{ xs: 4, md: 8 }} gap={3} alignItems="start">
          <Typography variant="h4">The Woolshed Bothy at Upper Dinvin Farm</Typography>
          <Typography variant="h6">
            Come and stay on our farm. The Woolshed is a newly refurbished studio apartment adjacent to a small quiet farm yard
            nestled within the breathtaking landscapes of the Rhins of Galloway. It offers a truly enchanting escape for those
            seeking tranquility and a genuine connection with nature. Surrounded by rolling hills and misty moors, The Woolshed
            provides a cosy and intimate retreat, blending rustic charm with modern comforts. It is the perfect haven for
            explorers and walkers (within 1/2 mile of the Southern Upland way).
          </Typography>
          <List style={{ listStyleType: "circle", padding: 0 }}>
            <ListItem style={{ display: "list-item" }}>
              <Typography variant="h6">Max occupancy: 2</Typography>
            </ListItem>
            <ListItem style={{ display: "list-item" }}>
              <Typography variant="h6">Minimum stay: 2 nights</Typography>
            </ListItem>
            <ListItem style={{ display: "list-item" }}>
              <Typography variant="h6">Super king bed (which can be set up as twin beds)</Typography>
            </ListItem>
            <ListItem style={{ display: "list-item" }}>
              <Typography variant="h6">Well equipped kitchen</Typography>
            </ListItem>
            <ListItem style={{ display: "list-item" }}>
              <Typography variant="h6">Shower room</Typography>
            </ListItem>
            <ListItem style={{ display: "list-item" }}>
              <Typography variant="h6">Free parking</Typography>
            </ListItem>
            <ListItem style={{ display: "list-item" }}>
              <Typography variant="h6">Wi-Fi</Typography>
            </ListItem>
            <ListItem style={{ display: "list-item" }}>
              <Typography variant="h6">Dogs welcomed</Typography>
            </ListItem>
          </List>
          <Stack width="100%" alignItems="center">
            <a href="mailto:sophie.morgan@killantringanfarms.com">
              <Button variant="contained">Book Now</Button>
            </a>
          </Stack>
        </SectionItem>
      </Section>
    </div>
  );
};

export default Accommodation;
