import { Typography } from "@mui/material";
import IMG_2793 from "../assets/IMG_2793.jpeg";
import IMG_4893 from "../assets/IMG_4893.png";
import IMG_6315 from "../assets/IMG_6315.png";
import IMG_6976 from "../assets/IMG_6976.png";
import IMG_6337 from "../assets/IMG_6337.png";
import IMG_6807 from "../assets/IMG_6807.png";
import { FirstSection, Section, SectionItem } from "../styles/Layout";
import { colors } from "../theme";

const About: React.FC = () => {
  return (
    <div className="about-page">
      <FirstSection>
        <img src={IMG_4893} alt="cows" />
      </FirstSection>

      <Section direction={{ xs: "column", md: "row-reverse" }}>
        <SectionItem
          gap={2}
          flexBasis={{ xs: "100%", md: "50%" }}
          padding={{ xs: 4, md: 8 }}
          borderBottom={{ xs: `2px solid ${colors.dark}`, md: 0 }}
        >
          <Typography variant="h3">The People</Typography>
          <Typography>Harry and Sophie Morgan came to Killantringan from Wales to manage the farm in October 2021.</Typography>
          <Typography>
            "They make a fabulous team. I am in awe of their good nature, boundless energy, huge enthusiasm and appreciation of
            the beautiful surroundings we are lucky enough to live in. They have a huge sense of fun and I love working with
            them."
          </Typography>
          <Typography fontStyle="italic">Janie Dear - Farm Owner</Typography>
        </SectionItem>
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }} border>
          <img src={IMG_2793} alt="story" />
        </SectionItem>
      </Section>

      <Section direction={{ xs: "column", md: "row" }}>
        <SectionItem
          gap={2}
          flexBasis={{ xs: "100%", md: "50%" }}
          border
          padding={{ xs: 4, md: 8 }}
          borderBottom={{ xs: `2px solid ${colors.dark}`, md: 0 }}
        >
          <Typography variant="h3">The Environment</Typography>
          <Typography>
            "Mob grazing" mean the grasses and pasture have a short, intense period of being eaten and then long periods of just
            growing. Allowing the grasses to grow to maturity has an exponential effect on the amount of carbon sequestered from
            the atmosphere back into the ground. Longer grasses and a variety of species promote a rich habitat for insects above
            and below ground, supporting a much more vibrant ecosystem of bugs, butterflies, birds, etc.
          </Typography>
        </SectionItem>
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }}>
          <img src={IMG_6315} alt="environment" />
        </SectionItem>
      </Section>

      <Section direction={{ xs: "column", md: "row-reverse" }}>
        <SectionItem
          gap={2}
          flexBasis={{ xs: "100%", md: "50%" }}
          padding={{ xs: 4, md: 8 }}
          borderBottom={{ xs: `2px solid ${colors.dark}`, md: 0 }}
        >
          <Typography variant="h3">The Soil</Typography>
          <Typography>
            "Mob grazing" cattle involves keeping the herd closely bunched together, mimicking how they would graze in the wild
            when they need to protect themselves from predators, and moving them often, normally every day. Grazing pasture this
            way results in a short, intense period of being eaten, pooed on, and trampled; which builds soil and soil carbon. The
            pasture is then left alone for many weeks, allowing it to grow, flower, and seed. This encourages a more diverse array
            of pasture - grasses, legumes, wildflowers, and herbs; which in turn means more varied root structures below ground as
            well. The above-ground pasture volume reduces rainwater evaporation, preserving moisture, and the robust root system
            aids water retention and reduces water runoff, thus preventing soil degradation. We aim to create a nutrient-rich
            environment above and below ground for wildlife, insects, earthworms, mycorrhizal fungi, all of which are vital for
            soil health.
          </Typography>
        </SectionItem>
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }} border>
          <img src={IMG_6976} alt="soil" />
        </SectionItem>
      </Section>

      <Section direction={{ xs: "column", md: "row" }}>
        <SectionItem
          gap={2}
          border
          flexBasis={{ xs: "100%", md: "50%" }}
          padding={{ xs: 4, md: 8 }}
          borderBottom={{ xs: `2px solid ${colors.dark}`, md: 0 }}
        >
          <Typography variant="h3">The Animal</Typography>
          <Typography>
            The cattle and sheep are moved frequently, usually every day, and are thus presented with fresh grazing. They are
            given a measured area, aiming to take approximately the top third of the plants, leaving two thirds behind to be pooed
            on and trampled. This fresh variety of grasses, herbs, legumes, and flowers is healthier and more nutritious for them
            than a 'set-stocked' system, where diversity tends to give way to monoculture. Diversity also allows the cattle to
            self-select plants, some of which have medicinal properties, as the animals know what is good for them. We aim to
            avoid them eating the bottom third of grasses where the worms reside, thus reducing the need for worming and having a
            positive impact on the soil as wormers get excreted and can harm soil life. The sheep and cattle are outside all year
            and may need to be fed preserved pasture forage (silage, haylage, or hay). Bale grazing helps to bring new seeds to
            the pasture.
          </Typography>
        </SectionItem>
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }}>
          <img src={IMG_6337} alt="animal" />
        </SectionItem>
      </Section>

      <Section direction={{ xs: "column", md: "row-reverse" }}>
        <SectionItem
          gap={2}
          flexBasis={{ xs: "100%", md: "50%" }}
          padding={{ xs: 4, md: 8 }}
          borderBottom={{ xs: `2px solid ${colors.dark}`, md: 0 }}
        >
          <Typography variant="h3">The Taste</Typography>
          <Typography>
            "Grass-fed" means the cattle and sheep solely eat a diverse mix of grasses, herbs, flowers, and legumes, without any
            grain or cereals. This natural diet results in the meat being lower in total fat, higher in Omega 3 fatty acids,
            higher in linoleic acid, and richer in vitamins and minerals than grain-fed beef. All of this makes it a lot better
            for you, and it tastes better too. Please try it for yourself and let us know what you think.
          </Typography>
        </SectionItem>
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }} border>
          <img src={IMG_6807} alt="taste" />
        </SectionItem>
      </Section>
    </div>
  );
};

export default About;
