import React from "react";
import { Typography } from "@mui/material";
import IMG_3243 from "../assets/IMG_3243.png";
import IMG_0313 from "../assets/IMG_0313.png";
import { Section, SectionItem } from "../styles/Layout";

const Contact: React.FC = () => {
  return (
    <>
      <Section direction="row">
        <SectionItem>
          <Typography variant="h3" paddingY={{ xs: 2, md: 4 }}>
            Say Hello
          </Typography>
        </SectionItem>
      </Section>

      <Section direction="row">
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }} border>
          <img src={IMG_3243} alt="lighthouse" />
        </SectionItem>

        <SectionItem justifyContent="center" padding={{ xs: 4, md: 8 }} flexBasis={{ xs: "100%", md: "50%" }}>
          <Typography variant="h3">Harry Morgan</Typography>
          <Typography letterSpacing={0.34} marginY={2}>
            <a href="mailto:harry.morgan@killantringanfarms.com">harry.morgan@killantringanfarms.com</a>
          </Typography>
          <Typography letterSpacing={0.34}>07931859580</Typography>
        </SectionItem>
      </Section>

      <Section direction="row">
        <SectionItem justifyContent="center" padding={{ xs: 4, md: 8 }} flexBasis={{ xs: "100%", md: "50%" }}>
          <Typography variant="h3">Sophie Morgan</Typography>
          <Typography letterSpacing={0.34} marginY={2}>
            <a href="mailto:sophie.morgan@killantringanfarms.com">sophie.morgan@killantringanfarms.com</a>
          </Typography>
        </SectionItem>
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }} border>
          <img src={IMG_0313} alt="lighthouse" />
        </SectionItem>
      </Section>
    </>
  );
};

export default Contact;
